var WP8FormValidationFunctions = {
    required : {
        message: "Por favor, preencha o campo {0}.",   
        func: function(obj){
            if(typeof obj.val == "function")
                if(obj.val() == "")
                    return false;
                else
                    return true;
            else
                return false;
        }
    },   
    tel: {
        message: "Por favor, preencha com um telefone válido.",
        func: function(obj){
            if(typeof obj.val == "function"){
                var filtro_tel = /\(\d{2}\)(?:\d{4}|\d{5})-\d{4}/;
                if(!filtro_tel.test(obj.val())){
                    return false;
                }else{
                    return true;
                }
            }else
                return false;
        }
    },
    email: {
        message: "Por favor, preencha com um e-mail válido.",   
        func: function(obj){
            if(typeof obj.val == "function"){
                var filtro_mail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!filtro_mail.test(obj.val())){
                    return false;
                }else{
                    return true;
                }
            }else
                return false;
        }
    },
    data: {
        /*Exemplo: "O campo {0} deve ser preenchido com uma DATA válida."*/
        message: "Por favor, preencha com uma data válida.",   
        func: function(obj){
            if(typeof obj.val == "function"){
                var data = obj.val();
                var dataExplodida = data.split("/");
                var dataObj = new Date(dataExplodida[2],(dataExplodida[1]-1),dataExplodida[0],12,0,0);
                if(parseInt(dataObj.getFullYear()) != parseInt(dataExplodida[2]) || parseInt(dataObj.getMonth() + 1) != parseInt(dataExplodida[1]) || parseInt(dataObj.getDate()) != parseInt(dataExplodida[0])){//comparando a atual com a passada, se for diferente retorna false
                    return false;
                }else {
                    return true;
                }
            }else
                return false;
        }
    },
    cpf: {
        message: "Por favor, preencha com um CPF válido.",   
        func: function(obj){
            if(typeof obj.val == "function"){
                var cpf = obj.val();
                cpf = cpf.replace(/[^\d]+/g,'').trim();
                if(cpf === '') return false;
                if (cpf.length != 11 || 
                        cpf === "00000000000" || 
                        cpf === "11111111111" || 
                        cpf === "22222222222" || 
                        cpf === "33333333333" || 
                        cpf === "44444444444" || 
                        cpf === "55555555555" || 
                        cpf === "66666666666" || 
                        cpf === "77777777777" || 
                        cpf === "88888888888" || 
                        cpf === "99999999999")
                        return false;

                var add = 0;
                for (i=0; i < 9; i ++)
                    add += parseInt(cpf.charAt(i)) * (10 - i);
                var rev = 11 - (add % 11);
                if (rev === 10 || rev === 11)
                    rev = 0;
                if (rev != parseInt(cpf.charAt(9)))
                    return false;

                add = 0;
                for (i = 0; i < 10; i ++)
                    add += parseInt(cpf.charAt(i)) * (11 - i);
                rev = 11 - (add % 11);
                if (rev === 10 || rev === 11)
                    rev = 0;
                if (rev != parseInt(cpf.charAt(10)))
                    return false;
                return true;
            }else
                return false;
        }
    }
};
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}
(function( $ ){
    $.fn.WP8FormValidation = function(options) {
        return this.each(function() {
            var defaults = {
                breakLine: "\n",
                validOnBlur: false,
                onInputError : function(obj,message,evento){
                    obj.css("border","1px solid red");
                },
                onInputOk : function(obj){
                    obj.attr('style', function(i, style){return style.replace(/border[^;]+;?/g, '');});
                },
                onFormSubmitError: function(message){
                    
                }
            };
            var settings = $.extend( {}, defaults, options );
            this.functions = WP8FormValidationFunctions;
            var validate = function(objValidar,evento){
                var flag = true;
                var inputMessage = "";
                for (var i in objValidar.regras) {
                    if(flag){
                        var nome    = objValidar.regras[i];
                        if(typeof WP8FormValidationFunctions[nome] == 'undefined'){
                            flag = false;
                            window.console.log("Regra "+nome+" não foi declarada.");
                        }else{
                            var fn      = WP8FormValidationFunctions[nome].func;
                            if(typeof fn !== 'function'){
                                flag = false;
                                window.console.log("A função para validar a regra "+nome+" não foi declarada.");
                            }else{
                                var message = WP8FormValidationFunctions[nome].message;
                                if(typeof message === "undefined" ){
                                    flag = false;
                                    window.console.log("A mensagem de erro a regra "+nome+" não foi declarada.");
                                }else{
                                    if(!fn(objValidar.obj)){
										if(settings.validOnBlur || evento == "submit"){
											var label = $("label[for='"+objValidar.obj.prop("id")+"']").text().toUpperCase();
											inputMessage = message.format(label);
											settings.onInputError(objValidar.obj,inputMessage,evento);
											flag = false;
										}
                                    }else{
                                        settings.onInputOk(objValidar.obj);
                                    }
                                }
                            }
                        }
                    }
                }
                return {erro: flag, message: inputMessage};
            };
            
            var objs = [];
            $(this).find("*[data-validate=true]").each(function(){
                var def = {
                    obj: $(this),
                    regras: $(this).data("rules").split(" ")
                };
                objs.push(def);
				$(this).on("blur",function(){
					validate(def,"blur");
				});
            });
            this.objs = objs;
            
            $(this).on("submit",function(event){
                var flag = true;
                var messages = "";
                for (var index in this.objs) {
                    var retorno = validate(objs[index],"submit");
                    if(retorno.erro == false){
                        flag = false;
                        messages += retorno.message+settings.breakLine;
                    }
                }
                if(!flag){
                    settings.onFormSubmitError(messages);
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                }
            });
        });
    }; 
})( jQuery );